<template>
    <div class="view">
        <div class="d-flex mt10 mb10">
            <div style="margin-left: auto">
                <el-button type="primary" icon="el-icon-refresh" @click="getList"></el-button>
                <el-button class="ml10" type="primary" @click="showAddModel">新增广告图</el-button>
            </div>
        </div>
        <div class="w100p d-flex flex-wrap-y" v-if="tableData.length > 0" v-loading="loading">
            <div class="adContainer mr10 ml10 mb10 mb10 posre" v-for="(row, index) in tableData" :key="index">
                <el-image class="adImg br5 shadow-gray50" :src="row.advertUrl" fit="cover"></el-image>
                <div class="adTextContent flex-c-c br5"
                    :style="{ background: row.advertTitle && row.advertContent ? 'rgba(0, 0, 0, 0.3)' : '' }">
                    <div class="adText">
                        <div class="adTitle">{{ row.advertTitle }}</div>
                        <div class="adContent">{{ row.advertContent }}</div>
                    </div>
                    <div class="adUpdate flex-c-c" @click="showUpdateAdImgModel(row)">
                        <div class="el-icon-edit-outline"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-c-c mb30" v-else>
            <p>暂无数据</p>
        </div>
        <div>
            <el-pagination v-if="tableData.length > 0" background :page-size="pageSize" @size-change="sizeChange"
                @current-change="currentChange" :current-page="currentPage" :page-sizes="[5, 10, 15]"
                layout="total, sizes, prev, pager, next, jumper" class="" :total="total" :pager-count="5" />
        </div>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="adModelTitle"
            :visible.sync="adShowModel" :destroy-on-close="true" width="630px" center @close="closeFun">
            <div class="flex-j-c">
                <div class="mr20">
                    <el-upload class="adUpload" :action="$store.state.uploadingUrl" list-type="picture-card"
                        :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="linkmanImgProgress"
                        :on-success="linkmanImgSuccess" :on-remove="linkmanImgRemove" :file-list="advertUrl">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <div class="textc mt20 w250">
                        <p>最多只能上传1张，建议上传230×500，格式可为png、jpg或jpeg</p>
                    </div>
                </div>
                <el-form :model="adFormData" label-position="top" :rules="adTypeRules" ref="adFormData" label-width="100px">
                    <el-form-item label="广告标题" prop="advertTitle">
                        <el-input class="w250" v-model="adFormData.advertTitle" placeholder="请输入广告标题"></el-input>
                    </el-form-item>
                    <el-form-item label="广告内容" prop="advertContent">
                        <el-input class="w250" type="textarea" rows="7" v-model="adFormData.advertContent"
                            placeholder="请输入广告内容"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="adShowModel = false">取 消</el-button>
                <el-button type="primary" @click="adSubmit" :loading="loadingBtn">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { setAdvertModel, getAdvertModelList, } from "@/api/miniProgram";
export default {
    name: "appletAdTable",
    data() {
        return {
            //列表配置
            loading: false, //表格加载
            tableData: [],
            userInfo: this.$store.state.loginRoot.userInfo,
            contentType: {},
            //编辑新增配置
            adModelTitle: "",
            adShowModel: false,
            advertUrl: [],
            adFormData: {
                advertUrl: "",
                advertTitle: "",
                advertContent: ""
            },
            adTypeRules: {
                // typeName: {
                //     required: true,
                //     trigger: "blur",
                //     message: "请输入类型名称",
                // },
                // contentType: {
                //     required: true,
                //     trigger: "chnage",
                //     message: "请选择类型",
                // },
            },
            formType: "",
            updateId: 0,
            loadingBtn: false,
            total: 0,
            currentPage: 1,
            pageSize: 5,
            status: "adImg",
        };
    },
    computed: {
        uploadDisabled() {
            return this.adFormData.advertUrl !== "";
        }
    },
    created() {
        this.getList();
    },
    methods: {
        closeFun() {
            this.adFormData.advertUrl = "";
            this.advertUrl = [];
        },
        /**@method 删除图片 */
        linkmanImgRemove(file, fileList) {
            //-console.log(file, fileList);
            this.adFormData.advertUrl = "";
            this.advertUrl = [];
        },
        /**@method 图片上传 */
        linkmanImgProgress(event, file, fileList) {

        },
        /**@method 上传回调 */
        linkmanImgSuccess(response, file, fileList) {
            this.$message.closeAll();
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.adFormData.advertUrl = `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`;
        },
        // /**@method 获取字典数据 */
        // getByDictType() {
        //     return getByDictType({ dictType: "content_type", status: 0 }).then(res => {
        //         if (res.code === 200) {
        //             let contentType = {};
        //             for (let row of res.data.sysDictDataList) {
        //                 contentType[row.dictValue] = row.dictLabel;
        //             }
        //             this.contentType = contentType;
        //         }
        //     })
        // },
        sizeChange(val) {
            this.currentPage = 1
            this.pageSize = val;
            this.getList()
        },
        currentChange(val) {
            this.currentPage = val;
            this.getList()
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
            };
            try {
                this.loading = true;
                let res = await getAdvertModelList(params)
                this.loading = false;

                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    tableData.push(row);
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
            } catch (error) {
               //console.log(error);
                this.loading = false;
                this.tableData = [];
            }
        },
        showAddModel() {
            this.adShowModel = true;
            this.adModelTitle = "新增广告图"
            this.formType = "add";
            this.updateId = 0;
            this.adFormData = {
                advertUrl: "",
                advertTitle: "",
                advertContent: "",
            }
        },
        /**@method 编辑
         * @param {Object} row - 当前点击行的值
         */
        showUpdateAdImgModel(row) {
            this.adShowModel = true;
            this.adModelTitle = "编辑广告图"
            this.formType = "update";
            this.updateId = row.adId;
            this.adFormData = {
                advertUrl: row.advertUrl ? row.advertUrl : "",
                advertTitle: row.advertTitle,
                advertContent: row.advertContent
            };
            if (row.advertUrl) {
                this.advertUrl = [{ url: row.advertUrl }]
            }

        },
        /**@method 修改添加广告图 */
        adSubmit() {
            this.$refs.adFormData.validate((valid) => {
                if (valid) {
                    if (!this.adFormData.advertUrl) {
                        this.$message({
                            type: "info",
                            message: "请上传广告图"
                        })
                        return;
                    }
                    let parmas = {
                        ...this.adFormData
                    }
                    let message = "新增成功"
                    if (this.formType === 'update') {
                        parmas.adId = this.updateId
                        message = "编辑成功";
                    }
                    this.loadingBtn = true;
                    setAdvertModel(parmas).then(res => {
                        this.loadingBtn = false;
                        if (res.code === 200) {
                            this.$message({
                                message: message,
                                type: "success"
                            })
                            this.adShowModel = false;
                            this.getList();
                        } else {
                            this.$message.error(err.message)
                        }
                    }).catch(err => {
                        this.loadingBtn = false;
                        if (err.code === 201) {
                            this.$message.error(err.message)
                        }
                    })
                }
            });
        }
    },
};
</script>
<style>
.adUpload .el-upload--picture-card,
.adUpload .el-upload-list--picture-card .el-upload-list__item {
    height: 500px !important;
    width: 250px !important;
    line-height: 500px !important;
}

.adUpload .el-upload-list--picture-card .el-upload-list__item-status-label .el-icon-check {
    position: absolute !important;
    bottom: 1px !important;
    left: 13px !important;
    margin: 0 !important;
}
</style>
<style lang="scss" scoped>
.adContainer {
    width: calc(20% - 20px);
    display: inherit;

    .adImg {
        width: 100%;
        height:68vh;
    }
}

.adTextContent {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: #fff;
    text-align: center;
    padding: 0 20px;
    cursor: pointer;

    .adTitle {
        font-weight: bold;
        font-size: 20px;
    }

    .adContent {
        font-size: 20px;
    }

    .adUpdate {
        border-radius: 50%;
        background: #51cbcd;
        width: 65px;
        height: 65px;
        display: none;

        .el-icon-edit-outline {
            font-size: 35px;
        }
    }
}

.adTextContent:hover {
    background: rgba(0, 0, 0, 0.3);
}

.adTextContent:hover .adText {
    display: none;
}

.adTextContent:hover .adUpdate {
    display: flex;
}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>