<template>
    <div class="view pa24">
        <el-tabs v-model="status">
            <el-tab-pane label="广告关联" name="adImgLink"></el-tab-pane>
            <el-tab-pane label="广告图库" name="adImg"></el-tab-pane>
        </el-tabs>
        <div v-show="status=='adImgLink'">
            <ad-img-link></ad-img-link>
        </div>
        <div v-show="status=='adImg'">
            <ad-img></ad-img>
        </div>
    </div>
</template>

<script>
import adImg from '@/pages/miniProgram/components/adImg'
import adImgLink from '@/pages/miniProgram/components/adImgLink'
export default {
    name: "appletAdTable",
    components: {
        adImg,
        adImgLink
    },
    data() {
        return {
            status: "adImgLink",
        };
    }
};
</script>